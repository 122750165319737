import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudyteladochealthmicrosoft.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-mn-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2.5}
                            height={pageContext.acf.client_logo.media_details.height / 2.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4" style={{ display: "none" }}>
                        <a href="https://www.meritmile.com/uploads/2022/11/microsoft-and-nuance-lead-generation-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Challenge</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>Microsoft and <a href="https://www.teladoc.com" target="_blank" rel="noopener">Teladoc Health</a> had a shared business objective of generating new sales opportunities within a specific set of target account companies. They wanted to create a mid-funnel pipeline for Teladoc Health virtual healthcare solutions, especially SoloTM with Teams, which integrates the Teladoc Health corporate virtual care platform with the communication and collaboration of Microsoft Teams.</p>
                        <p>The campaign needed to move quickly, from planning to delivering a pre-set goal of qualified leads all within a single quarter. However, they encountered three major obstacles: the internal teams lacked the time and resources to plan and run a campaign successfully, they needed to craft a persuasive joint value proposition that highlighted the strategic partnership between Microsoft and Teladoc Health, and they needed to engage with a very niche C-level audience within their target accounts.</p>
                        <p>To overcome these challenges, they partnered with Merit Mile, a full-service marketing agency with deep expertise in Microsoft partner marketing and ABM. Merit Mile helped them develop a thought leadership and ABM campaign, showcasing AI's impact on virtual healthcare solutions and how Teladoc Health and Microsoft are leading the way in this space.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                    <div className="col-md-6 text-center">
                        <img
                            src="https://www.meritmile.com/uploads/2024/05/teladoc-health-landing-page.webp"
                            className="results-image img-fluid"
                            alt="Teladoc Health Microsoft Case Study Solution "
                            width="750px"
                            style={{ marginBottom: '2em' }}
                        />
                        <img
                            src="https://www.meritmile.com/uploads/2024/05/himss-logo.webp"
                            className="intro-image"
                            width=""
                            alt={pageContext.yoast.opengraph_title}
                            style={{ marginBottom: "2em", maxWidth: "80%", marginLeft: "auto", marginRight: "auto", }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        &nbsp;
                    </div>
                    <div className="col-md-6">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2024/05/pdf-assetasset-4@2x.webp"
                            className="results-image img-fluid"
                            alt="Teladoc Health Microsoft Case Study Screenshot"
                            width="728px"
                            style={{ marginBottom: '2em' }}
                        />
                        <img
                            src="https://www.meritmile.com/uploads/2024/05/video-assetasset-5@2x.webp"
                            className="results-image img-fluid"
                            alt="Teladoc Health Microsoft Case Study Results"
                            width="728px"
                        />
                    </div>
                    <div className="col-md-6">
                        <p>For the cornerstone thought leadership offer, Merit Mile orchestrated the strategy, coordination, and production of a video featuring a healthcare industry executive from Microsoft and the President of Hospital and Health Systems at Teladoc Health. This content was strategically repurposed into three formats—a PDF for reading, a video for viewing, and a podcast for on-the-go listening—ensuring prospects could conveniently engage with the content wherever they were.</p>
                        <p>Once Merit Mile-produced the campaign elements—landing pages, emails, offers, etc.—the campaign went live with an <b>aggressive goal of 160 BANT qualified leads within eight weeks</b>. Leads were qualified using a BANT framework, verifying budget, authority, needs, and timeframe before being accepted. The campaign surpassed expectations, meeting the goal in just six weeks. To ensure the quality of lead opportunities, Merit Mile and HIMSS used a BANT-style qualified lead campaign, which involved a double touch strategy. This method made sure that leads interacted with Teladoc Health content at least twice and gave qualified answers to BANT discovery questions, thus removing early-stage leads and delivering high-value prospects.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                    <p>Merit Mile performed weekly quality control checks on the leads, making sure they met the target criteria, and built live campaign dashboards that showed performance metrics, analytics, and useful insights. As expected, the campaign successfully reached the desired C-suite and leadership audience, fulfilling its goals on budget, faster than planned, and with outstanding performance.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>Building on the success, Microsoft and Teladoc Health tapped Merit Mile for a second campaign expanding into additional target accounts. In parallel, Merit Mile developed a sales enablement playbook for Microsoft and Teladoc sellers, with the training and tools needed to efficiently co-sell and articulate the joint value proposition to customers, driving continued success and revenue growth.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-7">
                        <h3>Merit Mile Turnkey Campaign Services</h3>
                        <ul>
                            <li>Marketing and campaign strategy</li>
                            <li>Media buy and vendor management</li>
                            <li>Campaign execution and optimization</li>
                            <li>Campaign reporting and analytics</li>
                            <li>Copywriting and creative design</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-5 text-center">
                        <img
                            src="https://www.meritmile.com/uploads/2024/05/teladoc-health-chart.webp"
                            className="intro-image"
                            width=""
                            alt="Lead Chart"
                            style={{ marginBottom: "2em", maxWidth: "70%" }}
                        />
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your ABM, demand generation, and content marketing goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Teldoc%20Health%20Microsoft%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Teldoc%20Health%20Microsoft%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
